export enum TextTypes {
  navigationHeaderForm = 'navigationHeaderForm',
  headerForm = 'headerForm',

  documentationFormDemo = 'documentationFormDemo',
  documentationFormPDF = 'documentationFormPDF',

  controlQualityFormDemo = 'controlQualityFormDemo',
  controlQualityFormPDF = 'controlQualityFormPDF',

  digitalPactsFormDemo = 'digitalPactsFormDemo',
  digitalPactsFormPDF = 'digitalPactsFormPDF',

  accessibilityFormDemo = 'accessibilityFormDemo',
  accessibilityFormPDF = 'accessibilityFormPDF',

  productOverviewForm = 'productOverviewForm',
  howWeWorksForm = 'howWeWorksForm',
  digitalizationIndustryForm = 'digitalizationIndustryForm',
  footerForm = 'footerForm',

  managerProcurement = 'managerProcurement',
  monitoring = 'monitoring',
  security = 'security',
  event = 'event',
}

export enum FileNames {
  SMLT10D_Pass = 'SMLT10D_Pass.pdf',
  SMLT10D_Control = 'SMLT10D_Control.pdf',
  SMLT10D_Blueprint = 'SMLT10D_Blueprint.pdf',
  SMLT10D_KS_manager = 'SMLT10D_KS_manager.pdf',
  SMLT10D_Overview = 'SMLT10D_Overview.pdf',
  SMLT10D_Digitalization_Research = 'SMLT10D_Digitalization_Research.pdf',
}
