import { useContext } from 'react';

import { ModalContext } from '@shared/ui/layout/context';
import { FileNames, TextTypes } from '@shared/ui/modalForm/enum';

export default function OpenModalForm(type: TextTypes, fileName: FileNames | null) {
  const { setModalType, onOpenModal, setFileName } = useContext(ModalContext);

  return () => {
    setModalType(TextTypes[type]);

    setFileName(fileName);
    onOpenModal();
  };
}
