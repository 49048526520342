import { FormEvent, useCallback, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { METRIKA_GOALS, ROUTES } from '@shared/config';
import { ModalContext } from '@shared/ui/layout/context';
import '@shared/ui/tenFrame/index.css';
import { TextTypes } from '@shared/ui/modalForm/enum';
import EmailIcon from '@shared/ui/tenFrame/images/mail.svg';
import TelegramIcon from '@shared/ui/tenFrame/images/telegram.svg';

export const TenFrame = () => {
  const {
    isSuccessSend,
    formInputs,
    formCheckboxes,
    onSubmit,
    onOpenModal,
    setFileName,
  } = useContext(ModalContext);

  useEffect(() => {
    if (isSuccessSend !== null) {
      onOpenModal();
    }
  }, [isSuccessSend, onOpenModal]);

  const onSubmitForm = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setFileName(null);
      onSubmit(event, METRIKA_GOALS.submitFooterForm);
    },
    [onSubmit, setFileName],
  );

  return (
    <section className='ten-frame' id='contacts'>
      <div className='content'>
        <div className='description'>
          <h3 className='title-48 mb-24'>Оставьте ваши контакты</h3>
          <p className='text-20'>
            Мы позвоним, чтобы больше узнать о вашем бизнесе и договориться об удобном
            времени для презентации
          </p>
          <div className='contacts'>
            <div className='mail'>
              <h3 className='contacts-title'>Почта для связи</h3>
              <Link className='link' to={'mailto:SMLTtech@samolet.ru'}>
                <img src={EmailIcon} alt='email icon' loading='lazy' />
                <span>SMLTtech@samolet.ru</span>
              </Link>
            </div>
            <div className='telegram'>
              <h3 className='contacts-title'>Наш телеграм-канал</h3>
              <Link className='link' to={'https://t.me/+Evgj55WYO7QyMzAy'}>
                <img src={TelegramIcon} alt='telegram icon' loading='lazy' />
                <span>10D. Цифровая стройка</span>
              </Link>
            </div>
          </div>
        </div>
        <form onSubmit={onSubmitForm}>
          {formInputs}
          <input type='hidden' name='utm_form' value={TextTypes.footerForm}></input>
          <div>
            {formCheckboxes[0]}
            <span>
              Отправляя заявку, даю свое согласие на{' '}
              <Link to={ROUTES.consentFirst}>обработку персональных данных</Link> в
              соответствии с <Link to={ROUTES.policy}> политикой конфиденциальности</Link>
            </span>
          </div>
          <div>
            {formCheckboxes[1]}
            <span>
              Согласен на получение{' '}
              <Link to={ROUTES.agreementAd}>рекламных сообщений</Link> и{' '}
              <Link to={ROUTES.consentFirst}>
                {' '}
                обработку моих персональных данных в рекламных целях
              </Link>
            </span>
          </div>
          <button className='text-16' type='submit'>
            Заказать демо
          </button>
        </form>

        <div className='mobile-contacts'>
          <div className='mail-container'>
            <h3 className='contacts-title'>Почта для связи</h3>
            <Link className='link' to={'mailto:SMLTtech@samolet.ru'}>
              <img src={EmailIcon} alt='email icon' loading='lazy' />
              <span>SMLTtech@samolet.ru</span>
            </Link>
          </div>
          <div className='telegram'>
            <h3 className='contacts-title'>Наш телеграм-канал</h3>
            <Link className='link' to={'https://t.me/+Evgj55WYO7QyMzAy'}>
              <img src={TelegramIcon} alt='telegram icon' loading='lazy' />
              <span>10D. Цифровая стройка</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
