/// <reference lib="es2021" />
import classNames from 'classnames';
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import sbjs from 'sourcebuster';

import { apiClient } from '@shared/api';
import { METRIKA_COUNTER, METRIKA_GOALS } from '@shared/config';
import axiosQuery from '@shared/helpers/axiosQuery';
import PhoneMaskChange from '@shared/helpers/phoneMask';
import { FileNames } from '@shared/ui/modalForm/enum';
import '@shared/hooks/useForm.css';

export type FormInitialState = {
  [key: string]: {
    type: 'text' | 'checkbox';
    placeholder?: string;
    value: string | boolean;
    error: boolean;
  };
};

export const formInitialState: FormInitialState = {
  firstName: {
    type: 'text',
    placeholder: 'Имя *',
    value: '',
    error: false,
  },
  secondName: {
    type: 'text',
    placeholder: 'Фамилия *',
    value: '',
    error: false,
  },
  company: {
    type: 'text',
    placeholder: 'Компания *',
    value: '',
    error: false,
  },
  job: {
    type: 'text',
    placeholder: 'Должность',
    value: '',
    error: false,
  },
  telephone: {
    type: 'text',
    placeholder: 'Телефон *',
    value: '',
    error: false,
  },
  email: {
    type: 'text',
    placeholder: 'Email *',
    value: '',
    error: false,
  },
  consentToProcessing: {
    type: 'checkbox',
    placeholder:
      'Отправляя заявку, даю свое согласие на обработку персональных данных в соответствии с политикой конфиденциальности',
    value: false,
    error: false,
  },
  advertisingToProcessing: {
    type: 'checkbox',
    placeholder:
      'Согласен на получение рекламных сообщений и обработку моих персональных данных в рекламных целях',
    value: false,
    error: false,
  },
};

export const useForm = ({
  subject,
  fileName,
}: {
  subject?: string;
  fileName: FileNames | null;
}) => {
  const [formState, setFormState] = useState(formInitialState);
  const [isSuccessSend, setIsSuccessSend] = useState<null | boolean>(null);

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    // Маска для телефона
    if (event.target.name == 'telephone') {
      PhoneMaskChange(event);
    }

    setFormState(prevState => {
      return {
        ...prevState,
        [event.target.name as keyof FormInitialState]: {
          ...prevState[event.target.name],
          value:
            event.target.type === 'checkbox' ? event.target.checked : event.target.value,
          error: false,
        },
      };
    });
  }, []);

  const onSubmit = useCallback(
    (
      event: FormEvent<HTMLFormElement>,
      goalName?: (typeof METRIKA_GOALS)[keyof typeof METRIKA_GOALS],
    ) => {
      event.preventDefault();

      setFormState(prevState => {
        const afterValidateFormState: FormInitialState = {
          ...prevState,
          ...Object.keys(prevState).reduce((newState, key) => {
            let phoneError = 0;
            if (key == 'telephone') {
              const formattedNumber = (prevState[key].value as string)
                .replaceAll(' ', '')
                .replaceAll('(', '')
                .replaceAll(')', '')
                .replaceAll('-', '');
              if (
                (prevState[key].value as string).length != 24 ||
                formattedNumber.match(/(\d)\1{9,}/)
              ) {
                phoneError = 1;
              }
            }
            let mailError = 0;
            if (
              key == 'email' &&
              !(prevState[key].value as string).match(/^(.*)@(.*)\.\w{1}(.*)/)
            ) {
              mailError = 1;
            }

            if (key == 'advertisingToProcessing') {
              return {
                ...newState,
                [key]: {
                  ...prevState[key],
                  value: prevState[key].value,
                  error: null,
                },
              };
            }

            if (key == 'job') {
              return {
                ...newState,
                [key]: {
                  ...prevState[key],
                  value: prevState[key].value,
                  error: null,
                },
              };
            }

            return {
              ...newState,
              [key]: {
                ...prevState[key],
                value: prevState[key].value,
                error:
                  (prevState[key].value as string).length === 0 ||
                  !prevState[key].value ||
                  phoneError ||
                  mailError,
              },
            };
          }, {}),
        };

        if (Object.values(afterValidateFormState).every(item => !item.error)) {
          const data = Object.keys(afterValidateFormState).reduce(
            (formForSend, key) => {
              formForSend[key] = afterValidateFormState[key].value;

              return formForSend;
            },
            {} as Record<string, unknown>,
          );

          data.mdm = `${sbjs.get.current.src}/${sbjs.get.current.mdm}`;
          data.subject = subject;
          if (fileName) {
            data.fileLink = fileName;
          }

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ym(METRIKA_COUNTER, 'getClientID', (clientID: number) => {
            data.ymClientId = clientID;
          });

          console.debug('Отправляем:', data);
          console.debug('Имя файла:', fileName);

          apiClient
            .post('/api/index.php', data)
            .then(() => {
              setIsSuccessSend(true);

              if (goalName) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ym(METRIKA_COUNTER, 'reachGoal', goalName);
              }

              if (fileName) {
                setTimeout(() => {
                  window.open(`/pdf/${fileName}`, '_blank')!.focus();
                });
              }
            })
            .catch(() => setIsSuccessSend(false));
        }

        let formErrors = 0;
        Object.keys(afterValidateFormState).forEach(key => {
          if (afterValidateFormState[key].error) {
            formErrors = 1;
          }
        });

        if (!formErrors) {
          const target = event.target as HTMLFormElement;
          const formBtn = target.querySelector('button');
          let phone = target.telephone.value as string;

          formBtn!.disabled = true;

          phone = phone
            .replaceAll('-', '')
            .replaceAll('(', '')
            .replaceAll(')', '')
            .replaceAll(' ', '');

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          let ymClientId;

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ym(METRIKA_COUNTER, 'getClientID', (clientID: number) => {
            ymClientId = clientID;
          });

          // Вносим queryParams в переменную
          const queryParams = new URLSearchParams(window.location.search);

          // Сбор данных в обьект inputsData
          const inputsData = {
            firstName: target.firstName.value,
            secondName: target.secondName.value,
            fullname: target.firstName.value + ' ' + target.secondName.value,
            company: target.company.value,
            job: target.job.value,
            phone: phone,
            email: target.email.value,
            adAgreement: target.advertisingToProcessing.value == 'on' ? true : false,
            utm_form: target.utm_form.value,
            refer: `${sbjs.get.current.src}/${sbjs.get.current.mdm}`,
            utm_campaign: queryParams.get('utm_campaign'),
            utm_medium: queryParams.get('utm_medium'),
            utm_source: queryParams.get('utm_source'),
          };

          // Отправка данных
          axiosQuery(
            'POST',
            'https://tglk.ru/in/4zr7QUy9Ztn93PB4/',
            inputsData,
            {
              'Content-type': 'application/json; charset=UTF-8',
            },
            isSuccessSend,
            formBtn,
          );
        }

        return afterValidateFormState;
      });
    },
    [fileName, subject, isSuccessSend],
  );

  const formInputs = useMemo(() => {
    return Object.keys(formInitialState)
      .filter(item => formInitialState[item].type === 'text')
      .map((key, index, items) => {
        return (
          <div className='form-wrapper' key={key}>
            <input
              className={classNames({
                'mb-6': index === items.length - 1,
                'error-input': formState[key].error,
              })}
              name={key}
              placeholder={formState[key].placeholder}
              value={formState[key].value as string}
              onChange={onInputChange}
            />
          </div>
        );
      });
  }, [formState, onInputChange]);

  const formCheckboxes = useMemo(() => {
    return Object.keys(formInitialState)
      .filter(item => formInitialState[item].type === 'checkbox')
      .map(key => {
        return (
          <input
            key={key}
            className={classNames({
              'error-input':
                key == 'consentToProcessing'
                  ? formState.consentToProcessing.error
                  : formState.advertisingToProcessing.error,
            })}
            type='checkbox'
            name={key}
            checked={
              key == 'consentToProcessing'
                ? (formState.consentToProcessing.value as boolean)
                : (formState.advertisingToProcessing.value as boolean)
            }
            onChange={onInputChange}
          />
        );
      });
  }, [formState, onInputChange]);

  return {
    setFormState,
    isSuccessSend,
    setIsSuccessSend,
    formInputs,
    formCheckboxes,
    onSubmit,
  };
};
