import { useCallback, useContext, useEffect, useRef } from 'react';

import ScrollLikeMobile from '@shared/helpers/scrollLikeMobile';
import { ModalContext } from '@shared/ui/layout/context';
import { TextTypes } from '@shared/ui/modalForm/enum';
import IndicatorsIcon from '@shared/ui/seventhFrame/images/indicators.webp';
import '@shared/ui/seventhFrame/index.css';

export const SeventhFrame = () => {
  const { onOpenModal, setModalType } = useContext(ModalContext);

  const cardsSliderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ScrollLikeMobile(cardsSliderRef);
  }, [cardsSliderRef]);

  const onButtonClick = useCallback(
    (type: TextTypes) => {
      return () => {
        setModalType(TextTypes[type]);
        onOpenModal();
      };
    },
    [onOpenModal, setModalType],
  );

  return (
    <section className='seventh-frame'>
      <div className='header-block'>
        <div>
          <h2>Автоматизируйте</h2>
          <h2> стройку с нами</h2>
        </div>
      </div>

      <h3 className='title-40 mb-32' id='stages'>
        Как мы работаем
      </h3>
      <div className='mobile-scroll' ref={cardsSliderRef}>
        <div className='cards-wrapper'>
          <div className='custom-card'>
            <div className='circle mb-32'>1</div>
            <h4>Проводим демо</h4>
            <p>Демонстрируем возможности платформы и отвечаем на вопросы</p>
          </div>
          <div className='custom-card'>
            <div className='circle mb-32'>2</div>
            <h4>Заключаем соглашение</h4>
            <p>
              Подписываем договор, закрепляющий договоренности и условия сотрудничества
            </p>
          </div>
          <div className='custom-card'>
            <div className='circle mb-32'>3</div>
            <h4>Проводим пилот</h4>
            <p>
              Даем возможность протестировать систему на одном или нескольких проектах
            </p>
          </div>
          <div className='custom-card'>
            <div className='circle mb-32'>4</div>
            <h4>Масштабируем</h4>
            <p>
              Проводим обучение, сопровождаем в течение всего срока для успешной
              реализации проекта
            </p>
          </div>
        </div>
      </div>
      <div className='order-button'>
        <button onClick={onButtonClick(TextTypes.howWeWorksForm)}>Заказать демо</button>
      </div>

      <h3 className='title-40 mb-32' id='about-us'>
        О нас
      </h3>
      <div className='content-card'>
        <div className='description'>
          <p className='title'>
            Компания «Самолет Технологии» разрабатывает ИТ-решения для цифровизации
            строительной отрасли и отвечает за технологическое развитие ГК «Самолет».
            <br />
            <br />
            Платформа 10D — экосистема для цифровизации всех процессов девелоперского
            цикла и наше флагманское решение, доказавшее эффективность на практике.
          </p>
          <div className='indicators'>
            <div className='indicator'>
              <p>5 лет</p>
              <div>опыт трансформации бизнес-процессов девелопера</div>
            </div>
            <div className='indicator'>
              <p>20+</p>
              <div>решений для цифровизации строительного бизнеса</div>
            </div>
            <div className='indicator'>
              <p>12 тыс.</p>
              <div>пользователей различных продуктов платформы</div>
            </div>
          </div>
        </div>
        <img
          loading='lazy'
          className='indicators-img'
          src={IndicatorsIcon}
          alt='indicators icon'
        />
      </div>
    </section>
  );
};
