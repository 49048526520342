import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import OpenModalForm from '@shared/helpers/openModalForm';
import AccessKeysIcon from '@shared/ui/fourthFrame/images/accessKeys-icon.svg';
import BarcodeIcon from '@shared/ui/fourthFrame/images/barcode-icon.svg';
import ChevronDisabledIcon from '@shared/ui/fourthFrame/images/chevron-disabled-icon.svg';
import ChevronEnabledIcon from '@shared/ui/fourthFrame/images/chevron-enabled-icon.svg';
import ControlQualityFirstIcon from '@shared/ui/fourthFrame/images/control-quality-1.webp';
import ControlQualitySecondIcon from '@shared/ui/fourthFrame/images/control-quality-2.webp';
import ControlQualityThirdIcon from '@shared/ui/fourthFrame/images/control-quality-3.svg';
import CorrectFileIcon from '@shared/ui/fourthFrame/images/correctFile-icon.svg';
import DocumentationFirstIcon from '@shared/ui/fourthFrame/images/documentation-1.webp';
import DocumentationSecondIcon from '@shared/ui/fourthFrame/images/documentation-2.webp';
import DocumentationThirdIcon from '@shared/ui/fourthFrame/images/documentation-3.webp';
import ElectronicStampIcon from '@shared/ui/fourthFrame/images/electronicStamp-icon.svg';
import ExpandIcon from '@shared/ui/fourthFrame/images/expand-icon.svg';
import InteractiveMapIcon from '@shared/ui/fourthFrame/images/interactiveMap-icon.svg';
import ListIcon from '@shared/ui/fourthFrame/images/list-icon.svg';
import PhoneIcon from '@shared/ui/fourthFrame/images/phone-icon.svg';
import PhotoIcon from '@shared/ui/fourthFrame/images/photo-icon.svg';
import PlanningInspectionsIcon from '@shared/ui/fourthFrame/images/planningInpections-icon.svg';
import ProcurementManagementFirstIcon from '@shared/ui/fourthFrame/images/procurement-management-1.webp';
import ProcurementManagementSecondIcon from '@shared/ui/fourthFrame/images/procurement-management-2.webp';
import ProcurementManagementThirdIcon from '@shared/ui/fourthFrame/images/procurement-management-3.svg';
import SecurityFirstIcon from '@shared/ui/fourthFrame/images/security-1.svg';
import SecuritySecondIcon from '@shared/ui/fourthFrame/images/security-2.webp';
import SecurityThirdIcon from '@shared/ui/fourthFrame/images/security-3.webp';
import SettingsIcon from '@shared/ui/fourthFrame/images/settings-icon.svg';
import StoreIcon from '@shared/ui/fourthFrame/images/store-icon.svg';
import { FileNames, TextTypes } from '@shared/ui/modalForm/enum';

import '@shared/ui/fourthFrame/index.css';

const carouselInterval = 5000;

export const FourthFrame = () => {
  const carouselDocumentationRef = useRef<HTMLDivElement>(null);
  const [documentationActiveSlide, setDocumentationActiveSlide] = useState<number>(1);

  const carouselControlQualityRef = useRef<HTMLDivElement>(null);
  const [сontrolQualityActiveSlide, setControlQualityActiveSlide] = useState<number>(1);

  const carouselManagerProcurementRef = useRef<HTMLDivElement>(null);
  const [managerProcurementActiveSlide, setManagerProcurementActiveSlide] =
    useState<number>(1);

  const carouselSecurityRef = useRef<HTMLDivElement>(null);
  const [securityActiveSlide, setSecurityActiveSlide] = useState<number>(1);

  useEffect(() => {
    [
      carouselDocumentationRef,
      carouselControlQualityRef,
      carouselManagerProcurementRef,
      carouselSecurityRef,
    ].forEach(ref => {
      if (ref.current) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        new bootstrap.Carousel(ref.current, {
          interval: carouselInterval,
          ride: 'carousel',
        });

        ref.current.addEventListener(
          'slide.bs.carousel',
          function (e) {
            const target = e.target as HTMLTextAreaElement;

            setTimeout(() => {
              target.children[1].childNodes.forEach(element => {
                if (
                  element instanceof HTMLElement &&
                  element.classList.contains('active')
                ) {
                  const activeSlide = element.getAttribute('aria-label');
                  if (activeSlide == 'Slide 1') {
                    if (target.getAttribute('id') == 'carouselDocumentation') {
                      setDocumentationActiveSlide(1);
                    } else if (target.getAttribute('id') == 'carouselControlQuality') {
                      setControlQualityActiveSlide(1);
                    } else if (
                      target.getAttribute('id') == 'carouselManagerProcurement'
                    ) {
                      setManagerProcurementActiveSlide(1);
                    } else if (target.getAttribute('id') == 'carouselSecurity') {
                      setSecurityActiveSlide(1);
                    }
                  } else if (activeSlide == 'Slide 2') {
                    if (target.getAttribute('id') == 'carouselDocumentation') {
                      setDocumentationActiveSlide(2);
                    } else if (target.getAttribute('id') == 'carouselControlQuality') {
                      setControlQualityActiveSlide(2);
                    } else if (
                      target.getAttribute('id') == 'carouselManagerProcurement'
                    ) {
                      setManagerProcurementActiveSlide(2);
                    } else if (target.getAttribute('id') == 'carouselSecurity') {
                      setSecurityActiveSlide(2);
                    }
                  } else {
                    if (target.getAttribute('id') == 'carouselDocumentation') {
                      setDocumentationActiveSlide(3);
                    } else if (target.getAttribute('id') == 'carouselControlQuality') {
                      setControlQualityActiveSlide(3);
                    } else if (
                      target.getAttribute('id') == 'carouselManagerProcurement'
                    ) {
                      setManagerProcurementActiveSlide(3);
                    } else if (target.getAttribute('id') == 'carouselSecurity') {
                      setSecurityActiveSlide(3);
                    }
                  }
                }
              });
            }, 0);
          },
          true,
        );
      }
    });
  }, []);

  return (
    <section className='fourth-frame'>
      <div className='slider-card mb-64' id='documentation'>
        <div className='content'>
          <h4 className='title-40 mb-24'>Управление рабочей документацией</h4>
          <p className='text-20 mb-48'>
            Храните и распространяйте актуальные версии документации среди всех участников
            проекта. Предотвращайте работу по устаревшим чертежам и отслеживайте принятие
            новых версий подрядчиками.{' '}
            <Link className='more-link' to={'https://documentation.samolet10d.ru/'}>
              Подробнее
            </Link>
          </p>

          <div className='short-description mb-16'>
            <img loading='lazy' src={StoreIcon} alt='store icon' />
            <p>Структурированное хранение по проектам и быстрое сравнение версий</p>
          </div>
          <div className='short-description mb-16'>
            <img loading='lazy' src={ExpandIcon} alt='expand icon' />
            <p>Автоматическое распределение рабочей документации по подрядчикам</p>
          </div>
          <div className='short-description mb-48'>
            <img loading='lazy' src={ElectronicStampIcon} alt='qr icon' />
            <p>Электронные штампы и QR-коды</p>
          </div>
          <div className='btn-container'>
            <button
              className='secondary-button download'
              onClick={OpenModalForm(TextTypes.documentationFormDemo, null)}
            >
              Запросить демо
            </button>
            <button
              className='primary-button download'
              onClick={OpenModalForm(
                TextTypes.documentationFormPDF,
                FileNames.SMLT10D_Blueprint,
              )}
            >
              Скачать презентацию
            </button>
          </div>
        </div>
        <div
          ref={carouselDocumentationRef}
          id='carouselDocumentation'
          className='carousel slide pointer-event'
          data-bs-ride='carousel'
        >
          <div className='carousel-inner'>
            <div className='carousel-item active' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block'
                src={DocumentationFirstIcon}
                alt='slider image'
              />
            </div>
            <div className='carousel-item' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block'
                src={DocumentationSecondIcon}
                alt='slider image'
              />
            </div>
            <div className='carousel-item' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block'
                src={DocumentationThirdIcon}
                alt='slider image'
              />
            </div>
          </div>
          <div className='carousel-indicators'>
            <button
              className='active'
              type='button'
              data-bs-target='#carouselDocumentation'
              data-bs-slide-to='0'
              aria-current='true'
              aria-label='Slide 1'
            />
            <button
              type='button'
              data-bs-target='#carouselDocumentation'
              data-bs-slide-to='1'
              aria-label='Slide 2'
            />
            <button
              type='button'
              data-bs-target='#carouselDocumentation'
              data-bs-slide-to='2'
              aria-label='Slide 3'
            />
          </div>
          <button
            className='carousel-control-prev'
            type='button'
            data-bs-target='#carouselDocumentation'
            data-bs-slide='prev'
          >
            <img
              loading='lazy'
              src={
                documentationActiveSlide == 2 || documentationActiveSlide == 3
                  ? ChevronEnabledIcon
                  : ChevronDisabledIcon
              }
              alt='prev icon'
            />
          </button>
          <button
            className='carousel-control-next'
            type='button'
            data-bs-target='#carouselDocumentation'
            data-bs-slide='next'
          >
            <img
              loading='lazy'
              src={
                documentationActiveSlide == 1 || documentationActiveSlide == 2
                  ? ChevronEnabledIcon
                  : ChevronDisabledIcon
              }
              alt='next icon'
            />
          </button>
        </div>
      </div>

      <div className='slider-card mb-64' id='quality-control'>
        <div
          ref={carouselControlQualityRef}
          id='carouselControlQuality'
          className='carousel slide pointer-event'
          data-bs-ride='carousel'
        >
          <div className='carousel-inner'>
            <div className='carousel-item active' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block'
                src={ControlQualityFirstIcon}
                alt='control quality first'
              />
            </div>
            <div className='carousel-item' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block '
                src={ControlQualitySecondIcon}
                alt='control quality second'
              />
            </div>
            <div className='carousel-item' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block'
                src={ControlQualityThirdIcon}
                alt='control quality third'
              />
            </div>
          </div>
          <div className='carousel-indicators'>
            <button
              className='active'
              type='button'
              data-bs-target='#carouselControlQuality'
              data-bs-slide-to='0'
              aria-current='true'
              aria-label='Slide 1'
            />
            <button
              type='button'
              data-bs-target='#carouselControlQuality'
              data-bs-slide-to='1'
              aria-label='Slide 2'
            />
            <button
              type='button'
              data-bs-target='#carouselControlQuality'
              data-bs-slide-to='2'
              aria-label='Slide 3'
            />
          </div>
          <button
            className='carousel-control-prev'
            type='button'
            data-bs-target='#carouselControlQuality'
            data-bs-slide='prev'
          >
            <img
              loading='lazy'
              src={
                сontrolQualityActiveSlide == 2 || сontrolQualityActiveSlide == 3
                  ? ChevronEnabledIcon
                  : ChevronDisabledIcon
              }
              alt='prev icon'
            />
          </button>
          <button
            className='carousel-control-next'
            type='button'
            data-bs-target='#carouselControlQuality'
            data-bs-slide='next'
          >
            <img
              loading='lazy'
              src={
                сontrolQualityActiveSlide == 1 || сontrolQualityActiveSlide == 2
                  ? ChevronEnabledIcon
                  : ChevronDisabledIcon
              }
              alt='prev icon'
            />
          </button>
        </div>

        <div className='content'>
          <h4 className='title-40 mb-24'>Контроль качества строительства</h4>
          <p className='text-20 mb-48'>
            Управляйте контролем качества работ от котлована до передачи квартир.
            Фиксируйте замечания в мобильном приложении, сокращайте время их устранения и
            предотвращайте появление критичных дефектов.{' '}
            <a className='more-link' href='https://samolet10d.ru/campaigns/control'>
              Подробнее
            </a>
          </p>
          <div className='short-description mb-16'>
            <img loading='lazy' src={PhotoIcon} alt='photo icon' />
            <p className='text-16'>Рейтинг субподрядчиков</p>
          </div>
          <div className='short-description mb-16'>
            <img
              loading='lazy'
              src={PlanningInspectionsIcon}
              alt='planning inspections icon'
            />
            <p className='text-16'>Подробные отчёты о состоянии дефектов</p>
          </div>
          <div className='short-description mb-48'>
            <img loading='lazy' src={InteractiveMapIcon} alt='interactive map icon' />
            <p className='text-16'>
              Аналитика планового и фактического времени устранения дефектов
            </p>
          </div>
          <div className='btn-container'>
            <button
              className='secondary-button download'
              onClick={OpenModalForm(TextTypes.controlQualityFormDemo, null)}
            >
              Запросить демо
            </button>
            <button
              className='primary-button download'
              onClick={OpenModalForm(
                TextTypes.controlQualityFormPDF,
                FileNames.SMLT10D_Control,
              )}
            >
              Скачать презентацию
            </button>
          </div>
        </div>
      </div>

      <div className='slider-card mb-64' id='digital-pacts'>
        <div className='content pr-16'>
          <h4 className='title-40 mb-24'>Цифровые акты выполненных работ</h4>
          <p className='text-20 mb-48'>
            Автоматизируйте процесс сдачи объемов и формирования актов КС-2, КС-3 и КС-6а.
            Контролируйте соответствие выполненных работ проектной документации и
            сокращайте время согласования в два раза.
          </p>
          <div className='short-description mb-16'>
            <img loading='lazy' src={SettingsIcon} alt='accreditation icon' />
            <p className='text-16'>
              Корректировка заявленных объемов с учетом незакрытых дефектов
            </p>
          </div>
          <div className='short-description mb-16'>
            <img loading='lazy' src={ListIcon} alt='settings icon' />
            <p className='text-16'>
              Создание сводного акта по нескольким заявкам и подгрузка ИД
            </p>
          </div>
          <div className='short-description mb-48'>
            <img loading='lazy' src={CorrectFileIcon} alt='mobile icon' />
            <p className='text-16'>
              Мгновенное формирование комплекта закрывающих документов с ЭЦП
            </p>
          </div>
          <div className='btn-container'>
            <button
              className='secondary-button download'
              onClick={OpenModalForm(TextTypes.digitalPactsFormDemo, null)}
            >
              Запросить демо
            </button>
            <button
              className='primary-button download'
              onClick={OpenModalForm(
                TextTypes.digitalPactsFormPDF,
                FileNames.SMLT10D_KS_manager,
              )}
            >
              Скачать презентацию
            </button>
          </div>
        </div>
        <div
          ref={carouselManagerProcurementRef}
          id='carouselManagerProcurement'
          className='carousel slide pointer-event'
          data-bs-ride='carousel'
        >
          <div className='carousel-inner'>
            <div className='carousel-item active' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block'
                src={ProcurementManagementFirstIcon}
                alt='slider image'
              />
            </div>
            <div className='carousel-item' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block'
                src={ProcurementManagementSecondIcon}
                alt='slider image'
              />
            </div>
            <div className='carousel-item' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block'
                src={ProcurementManagementThirdIcon}
                alt='slider image'
              />
            </div>
          </div>
          <div className='carousel-indicators'>
            <button
              className='active'
              type='button'
              data-bs-target='#carouselManagerProcurement'
              data-bs-slide-to='0'
              aria-current='true'
              aria-label='Slide 1'
            />
            <button
              type='button'
              data-bs-target='#carouselManagerProcurement'
              data-bs-slide-to='1'
              aria-label='Slide 2'
            />
            <button
              type='button'
              data-bs-target='#carouselManagerProcurement'
              data-bs-slide-to='2'
              aria-label='Slide 3'
            />
          </div>
          <button
            className='carousel-control-prev'
            type='button'
            data-bs-target='#carouselManagerProcurement'
            data-bs-slide='prev'
          >
            <img
              loading='lazy'
              src={
                managerProcurementActiveSlide == 2 || managerProcurementActiveSlide == 3
                  ? ChevronEnabledIcon
                  : ChevronDisabledIcon
              }
              alt='prev icon'
            />
          </button>
          <button
            className='carousel-control-next'
            type='button'
            data-bs-target='#carouselManagerProcurement'
            data-bs-slide='next'
          >
            <img
              loading='lazy'
              src={
                managerProcurementActiveSlide == 1 || managerProcurementActiveSlide == 2
                  ? ChevronEnabledIcon
                  : ChevronDisabledIcon
              }
              alt='next icon'
            />
          </button>
        </div>
      </div>

      <div className='slider-card mb-64' id='accessibility'>
        <div
          ref={carouselSecurityRef}
          id='carouselSecurity'
          className='carousel slide pointer-event'
          data-bs-ride='carousel'
        >
          <div className='carousel-inner'>
            <div className='carousel-item active' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                src={SecurityFirstIcon}
                className='d-block'
                alt='security first icon'
              />
            </div>
            <div className='carousel-item' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                src={SecuritySecondIcon}
                className='d-block'
                alt='security second icon'
              />
            </div>
            <div className='carousel-item' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                src={SecurityThirdIcon}
                className='d-block'
                alt='security third icon'
              />
            </div>
          </div>
          <div className='carousel-indicators'>
            <button
              className='active'
              type='button'
              data-bs-target='#carouselSecurity'
              data-bs-slide-to='0'
              aria-current='true'
              aria-label='Slide 1'
            />
            <button
              type='button'
              data-bs-target='#carouselSecurity'
              data-bs-slide-to='1'
              aria-label='Slide 2'
            />
            <button
              type='button'
              data-bs-target='#carouselSecurity'
              data-bs-slide-to='2'
              aria-label='Slide 3'
            />
          </div>
          <button
            className='carousel-control-prev'
            type='button'
            data-bs-target='#carouselSecurity'
            data-bs-slide='prev'
          >
            <img
              loading='lazy'
              src={
                securityActiveSlide == 2 || securityActiveSlide == 3
                  ? ChevronEnabledIcon
                  : ChevronDisabledIcon
              }
              alt='prev icon'
            />
          </button>
          <button
            className='carousel-control-next'
            type='button'
            data-bs-target='#carouselSecurity'
            data-bs-slide='next'
          >
            <img
              loading='lazy'
              src={
                securityActiveSlide == 1 || securityActiveSlide == 2
                  ? ChevronEnabledIcon
                  : ChevronDisabledIcon
              }
              alt='prev icon'
            />
          </button>
        </div>

        <div className='content'>
          <h4 className='title-40 mb-24'>Цифровой контроль доступа на объекты</h4>
          <p className='text-20 mb-48'>
            Выдавайте пропуска сотрудникам и рабочим за несколько минут в мобильном
            приложении. Исключите затраты на магнитные карты и автоматизируйте учет
            рабочего времени.
          </p>
          <div className='short-description mb-16'>
            <img loading='lazy' src={BarcodeIcon} alt='pass icon' />
            <p className='text-16'>QR-код для прохода вместо магнитных карт</p>
          </div>
          <div className='short-description mb-16'>
            <img loading='lazy' src={PhoneIcon} alt='blocked document icon' />
            <p className='text-16'>Мобильное приложение на 3 языках</p>
          </div>
          <div className='short-description mb-48'>
            <img loading='lazy' src={AccessKeysIcon} alt='step icon' />
            <p className='text-16'>Гибкая настройка прав доступа</p>
          </div>
          <div className='btn-container'>
            <button
              className='secondary-button download'
              onClick={OpenModalForm(TextTypes.accessibilityFormDemo, null)}
            >
              Запросить демо
            </button>
            <button
              className='primary-button download'
              onClick={OpenModalForm(
                TextTypes.accessibilityFormPDF,
                FileNames.SMLT10D_Pass,
              )}
            >
              Скачать презентацию
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
