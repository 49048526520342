import { createContext, Dispatch, FormEvent, SetStateAction } from 'react';

import { METRIKA_GOALS } from '@shared/config';
import { FormInitialState } from '@shared/hooks/useForm';
import { FileNames, TextTypes } from '@shared/ui/modalForm/enum';

export const ModalContext = createContext<{
  isSuccessSend: boolean | null;
  fileName: FileNames | null;
  modalType: TextTypes;
  formInputs: JSX.Element[];
  formCheckboxes: JSX.Element[];
  setIsSuccessSend: Dispatch<SetStateAction<boolean | null>>;
  setFormState: Dispatch<SetStateAction<FormInitialState>>;
  setModalType: Dispatch<SetStateAction<TextTypes>>;
  setFileName: Dispatch<SetStateAction<null | FileNames>>;
  onOpenModal: () => void;
  onSubmit: (
    event: FormEvent<HTMLFormElement>,
    goalName?: (typeof METRIKA_GOALS)[keyof typeof METRIKA_GOALS],
  ) => void;
}>({
  isSuccessSend: null,
  fileName: null,
  modalType: TextTypes.headerForm,
  formInputs: [],
  formCheckboxes: [],
  setIsSuccessSend: () => {},
  setFormState: () => {},
  setModalType: () => {},
  setFileName: () => {},
  onOpenModal: () => {},
  onSubmit: () => {},
});
