import {
  FormEvent,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';

import { METRIKA_GOALS, ROUTES } from '@shared/config';
import { formInitialState } from '@shared/hooks/useForm';
import { ModalContext } from '@shared/ui/layout/context';
import { TextTypes } from '@shared/ui/modalForm/enum';
import '@shared/ui/modalForm/index.css';

const title = 'Получить презентацию';

const commonTextDemo = {
  title: title,
  description:
    'Мы позвоним, чтобы больше узнать о вашем бизнесе и договориться об удобном времени для презентации',
  buttonText: 'Заказать демо',
};

const commonTextPDF = {
  title: title,
  description:
    'Мы позвоним, чтобы больше узнать о вашем бизнесе и договориться об удобном времени для презентации',
  buttonText: 'Получить презентацию',
};

const getTextByType = (
  type: TextTypes,
): { title: string; description: string; buttonText: string } => {
  return {
    [TextTypes.navigationHeaderForm]: {
      ...commonTextDemo,
    },
    [TextTypes.headerForm]: {
      ...commonTextDemo,
    },
    [TextTypes.documentationFormDemo]: {
      ...commonTextDemo,
    },
    [TextTypes.documentationFormPDF]: {
      ...commonTextPDF,
    },
    [TextTypes.controlQualityFormDemo]: {
      ...commonTextDemo,
    },
    [TextTypes.controlQualityFormPDF]: {
      ...commonTextPDF,
    },
    [TextTypes.digitalPactsFormDemo]: {
      ...commonTextDemo,
    },
    [TextTypes.digitalPactsFormPDF]: {
      ...commonTextPDF,
    },
    [TextTypes.accessibilityFormDemo]: {
      ...commonTextDemo,
    },
    [TextTypes.accessibilityFormPDF]: {
      ...commonTextPDF,
    },
    [TextTypes.productOverviewForm]: {
      ...commonTextPDF,
    },
    [TextTypes.howWeWorksForm]: {
      ...commonTextDemo,
    },
    [TextTypes.digitalizationIndustryForm]: {
      ...commonTextPDF,
    },
    [TextTypes.footerForm]: {
      ...commonTextDemo,
    },
    [TextTypes.managerProcurement]: {
      ...commonTextDemo,
      title: 'Управление закупками',
    },
    [TextTypes.monitoring]: {
      ...commonTextDemo,
      title: 'ИИ-мониторинг',
    },
    [TextTypes.security]: {
      ...commonTextDemo,
      title: 'Безопасность',
    },
    [TextTypes.event]: {
      ...commonTextDemo,
    },
  }[type];
};

interface Props {
  onCloseClick: () => void;
}

export const ModalForm = forwardRef<HTMLDivElement, Props>(
  ({ onCloseClick }: Props, ref) => {
    const {
      modalType,
      isSuccessSend,
      fileName,
      formInputs,
      formCheckboxes,
      setModalType,
      setFileName,
      setFormState,
      setIsSuccessSend,
      onSubmit,
    } = useContext(ModalContext);

    const { buttonText } = useMemo(() => {
      return getTextByType(modalType);
    }, [modalType]);

    const onClose = useCallback(() => {
      onCloseClick();
      setFormState(formInitialState);
      setIsSuccessSend(null);
      setModalType(TextTypes.headerForm);
      setFileName(null);
    }, [onCloseClick, setFileName, setFormState, setIsSuccessSend, setModalType]);

    const onSubmitForm = useCallback(
      (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit(event, METRIKA_GOALS.submitPopupForm);
      },
      [onSubmit],
    );

    const FormContent = useMemo(() => {
      return (
        <>
          <form onSubmit={onSubmitForm}>
            {formInputs}
            <input type='hidden' name='utm_form' value={modalType}></input>
            <div className='mb-6'>
              {formCheckboxes[0]}
              <span>
                Отправляя заявку, даю свое согласие на{' '}
                <Link to={ROUTES.consentFirst} onClick={onClose}>
                  обработку персональных данных
                </Link>{' '}
                в соответствии с{' '}
                <Link to={ROUTES.policy} onClick={onClose}>
                  {' '}
                  политикой конфиденциальности
                </Link>
              </span>
            </div>
            <div className='mb-6'>
              {formCheckboxes[1]}
              <span>
                Согласен на получение{' '}
                <Link to={ROUTES.agreementAd} onClick={onClose}>
                  рекламных сообщений
                </Link>{' '}
                и{' '}
                <Link to={ROUTES.consentSecond} onClick={onClose}>
                  {' '}
                  обработку моих персональных данных в рекламных целях
                </Link>
              </span>
            </div>
            <button className='text-16' type='submit'>
              {buttonText}
            </button>
          </form>
        </>
      );
    }, [buttonText, modalType, formCheckboxes, formInputs, onClose, onSubmitForm]);
    const AfterSubmitContent = useMemo(() => {
      return (
        <>
          <h2 className='title-40 mb-24 text-center'>
            {isSuccessSend && fileName
              ? 'Спасибо за интерес!'
              : isSuccessSend
                ? 'Спасибо!'
                : 'Ошибка!'}
          </h2>
          <p className='text-20 text-center'>
            {isSuccessSend && fileName
              ? 'Отправили материалы на указанную почту'
              : isSuccessSend
                ? 'Скоро мы свяжемся с вами'
                : 'Попробуйте ещё раз'}
          </p>
        </>
      );
    }, [isSuccessSend, fileName]);

    const afterSubmitContentFunction = () => {
      if (isSuccessSend === null) {
        return FormContent;
      } else {
        return AfterSubmitContent;
      }
    };

    useEffect(() => {
      if (isSuccessSend !== null) {
        setTimeout(() => {
          setIsSuccessSend(null);
          onCloseClick();
        }, 1000);
      }
    }, [AfterSubmitContent, isSuccessSend, setIsSuccessSend, onCloseClick]);

    return (
      <div
        ref={ref}
        className='modal-form modal fade'
        tabIndex={-1}
        aria-labelledby='modal'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-body'>{afterSubmitContentFunction()}</div>
          </div>
        </div>
      </div>
    );
  },
);

ModalForm.displayName = 'ModalForm';
