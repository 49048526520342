/* eslint-disable @typescript-eslint/no-unused-vars */
import { Cookie } from '@shared/ui/cookie';
import { EightFrame } from '@shared/ui/eightFrame';
import { FifthFrame } from '@shared/ui/fifthFrame';
import { FirstFrame } from '@shared/ui/firstFrame';
import { FourthFrame } from '@shared/ui/fourthFrame';
import { Layout } from '@shared/ui/layout';
import { NinthFrame } from '@shared/ui/ninthFrame';
import { SecondFrame } from '@shared/ui/secondFrame';
import { SeventhFrame } from '@shared/ui/seventhFrame';
import { SixthFrame } from '@shared/ui/sixthFrame';
import { TenFrame } from '@shared/ui/tenFrame';
import { ThirdFrame } from '@shared/ui/thirdFrame';

export const Home = () => {
  return (
    <Layout>
      <Cookie />
      <FirstFrame />
      <SecondFrame />
      {/* <ThirdFrame /> */}
      <FourthFrame />
      <FifthFrame />
      <SixthFrame />
      <SeventhFrame />
      <EightFrame />
      {/* <NinthFrame /> */}
      <TenFrame />
    </Layout>
  );
};
