import { ChangeEvent } from 'react';

let memo: string;
const regionStartMemo = (value?: string) => {
  if (value) {
    memo = value;
  }

  return memo;
};

export default function PhoneMaskChange(phone: ChangeEvent<HTMLInputElement>) {
  const target = phone.target as HTMLInputElement;

  if (target.defaultValue == '') {
    regionStartMemo('0');
  }
  const memoReturn = regionStartMemo();

  // Если пользователь добавляет и это не автовставка или ctrl+v
  if (target.value.length > target.defaultValue.length) {
    //Если это не автовставка
    if (target.value.length - 1 == target.defaultValue.length) {
      // Если поле пустое и добавляется "8", "7", "+"
      const regionCodeArray = ['8', '7', '\\+'];
      regionCodeArray.forEach(digit => {
        const regEx = new RegExp('^' + digit + '$');
        if (target.value.match(regEx)) {
          target.value = '+7 ';
        }
      });

      // Если есть только "+7 " и добавляется 7
      if (target.value == '+7 7' && memoReturn != '+7 7') {
        regionStartMemo(target.value);
        target.value = '+7 ';
      }

      // Если введена не цифра
      if (target.value[target.value.length - 1].match(/\D/)) {
        target.value = target.defaultValue ? target.defaultValue : '+7 ';
      }

      // Если в начале введено не +7
      if (!target.value.match(/^\+7 /)) {
        const inputedValue = target.value[target.value.length - 1];
        target.value = '+7 ' + inputedValue;
      }

      // Если введено 4 цифры
      if (target.value.match(/^\+\d* (\d{4})$/)) {
        target.value =
          target.value[0] +
          target.value[1] +
          target.value[2] +
          '(' +
          target.value[3] +
          target.value[4] +
          target.value[5] +
          ')' +
          ' - ' +
          target.value[6];
      }

      // Если введено 7 цифр
      if (target.value.match(/^\+\d* (\(\d{3}\)) - (\d{4})$/)) {
        target.value =
          target.value.slice(0, -1) + ' - ' + target.value[target.value.length - 1];
      }

      // Если введено 9 цифр
      if (target.value.match(/^\+\d* (\(\d{3}\)) - (\d{3}) - (\d{3})$/)) {
        target.value =
          target.value.slice(0, -1) + ' - ' + target.value[target.value.length - 1];
      }
    } else {
      // Если номер введен, но не форматирован
      if (
        target.value.match(/^\+(\d{11})$/) &&
        !target.value.match(/^\+\d* (\(\d{3}\)) - \d$/)
      ) {
        target.value =
          target.value.slice(0, 2) +
          ' (' +
          target.value.slice(2, 5) +
          ') - ' +
          target.value.slice(5, 8) +
          ' - ' +
          target.value.slice(8, 10) +
          ' - ' +
          target.value.slice(10, 12);
      }
    }

    // Если введено больше 24 цифр
    target.value = target.value.slice(0, 24);
  }

  //Если пользователь удаляет
  if (target.value.length < target.defaultValue.length) {
    // Если удалены последние 2 цифры
    if (target.value.length == 22) {
      target.value = target.value.slice(0, 19);
    }

    // Если удалены последние 4 цифры
    if (target.value.length == 17) {
      target.value = target.value.slice(0, 14);
    }

    // Если удалены последние 5 цифр
    if (target.value.length == 11) {
      target.value = target.value.slice(0, 7);
      target.value = target.value.replace('(', '');
      target.value = target.value.replace(')', '');
    }
  }
}
