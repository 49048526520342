import '@shared/ui/sixthFrame/index.css';

import { useEffect, useRef } from 'react';

import BuildCenterIcon from '@shared/ui/sixthFrame/images/build-center-icon.png';
import FrameIcon from '@shared/ui/sixthFrame/images/frame-icon.png';
import FskIcon from '@shared/ui/sixthFrame/images/fsk-icon.png';
import GuardIcon from '@shared/ui/sixthFrame/images/guard-icon.png';
import SamoletIcon from '@shared/ui/sixthFrame/images/samolet-icon.png';
import TisIcon from '@shared/ui/sixthFrame/images/tis-icon.png';
import VideoPreview from '@shared/ui/sixthFrame/images/video-preview.png';
import VideoSrc from '@shared/ui/sixthFrame/video/Irkutsk.mp4';

interface Image {
  src: string;
  alt: string;
  style?: React.CSSProperties;
}

const images: Image[] = [
  {
    src: SamoletIcon,
    alt: 'samolet icon',
  },
  {
    src: FskIcon,
    alt: 'fsk icon',
  },
  {
    src: GuardIcon,
    alt: 'guard icon',
  },
  {
    src: FrameIcon,
    alt: 'frame icon',
  },
  {
    src: BuildCenterIcon,
    alt: 'build center icon',
  },
  {
    src: TisIcon,
    alt: 'tis icon',
  },
];

export const SixthFrame = () => {
  const videoDivRef = useRef<HTMLDivElement>(null);
  const cardsSliderRef = useRef<HTMLDivElement>(null);

  const scrollIntoElement = () => videoDivRef.current?.scrollIntoView();

  useEffect(() => {
    if (
      cardsSliderRef.current &&
      cardsSliderRef.current?.scrollWidth > cardsSliderRef.current?.offsetWidth
    ) {
      cardsSliderRef.current.classList.add('auto-slider-animation');
    } else if (
      cardsSliderRef.current &&
      cardsSliderRef.current?.scrollWidth <= cardsSliderRef.current?.offsetWidth
    ) {
      cardsSliderRef.current.classList.remove('auto-slider-animation');
    }

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams?.get('video') !== null) {
      scrollIntoElement();
    }
  }, [cardsSliderRef]);

  return (
    <section className='sixth-frame' id='ourClients'>
      <div className='content'>
        <h2>Наши клиенты</h2>
        <div className='cards-wrapper' ref={cardsSliderRef}>
          {images.map((card, index) => {
            return (
              <div className='custom-card' key={index}>
                <img
                  draggable='false'
                  src={card.src}
                  alt={card.alt}
                  style={card.style}
                  loading='lazy'
                />
              </div>
            );
          })}
          {images.map((card, index) => {
            return (
              <div className='custom-card' key={index}>
                <img
                  draggable='false'
                  src={card.src}
                  alt={card.alt}
                  style={card.style}
                  loading='lazy'
                />
              </div>
            );
          })}
          {images.map((card, index) => {
            return (
              <div className='custom-card' key={index}>
                <img
                  draggable='false'
                  src={card.src}
                  alt={card.alt}
                  style={card.style}
                  loading='lazy'
                />
              </div>
            );
          })}
        </div>

        <div className='big-card'>
          <video controls poster={VideoPreview}>
            <source src={VideoSrc} type='video/mp4' />
          </video>
        </div>
      </div>
    </section>
  );
};
