import { useCallback, useContext } from 'react';

import '@shared/ui/eightFrame/index.css';
import building from '@shared/ui/eightFrame/images/building.webp';
import firstClouds from '@shared/ui/eightFrame/images/clouds_first.webp';
import secondClouds from '@shared/ui/eightFrame/images/clouds_second.webp';
import verb from '@shared/ui/eightFrame/images/verb.svg';
import { ModalContext } from '@shared/ui/layout/context';
import { FileNames, TextTypes } from '@shared/ui/modalForm/enum';

export const EightFrame = () => {
  const { onOpenModal, setModalType, setFileName } = useContext(ModalContext);

  const onButtonClick = useCallback(
    (type: TextTypes, fileName: FileNames) => {
      return () => {
        setModalType(TextTypes[type]);
        setFileName(fileName);
        onOpenModal();
      };
    },
    [onOpenModal, setFileName, setModalType],
  );

  return (
    <section className='eight-frame'>
      <div className='content'>
        <div className='description'>
          <p className='research-title'>
            Исследование агентства SALT при поддержке Самолет Технологий
          </p>
          <h3 className='title'>Цифровизация строительной отрасли 2024</h3>

          <div className='table'>
            <div className='td'>
              <li>прогноз развития отрасли</li>
              <li>подходы к выбору цифровых решений</li>
              <li>критерии выбора</li>
            </div>
            <div className='td'>
              <li>рейтинг направлений для цифровизации</li>
              <li>показатели эффективности</li>
              <li>бюджетирование</li>
            </div>
          </div>

          <button
            onClick={onButtonClick(
              TextTypes.digitalizationIndustryForm,
              FileNames.SMLT10D_Digitalization_Research,
            )}
          >
            Скачать исследование
          </button>
        </div>

        <img className='building' src={building} alt='building' />
        <img className='verb' src={verb} alt='verb' />
        <img className='first-cloud' src={firstClouds} alt='first-clouds' />
        <img className='second-cloud' src={secondClouds} alt='second-clouds' />
      </div>
    </section>
  );
};
