import { useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import ScrollIntoView from 'react-scroll-into-view';

import { ROUTES } from '@shared/config';

import Logo from '/images/logo.svg';

import OpenModalForm from '@shared/helpers/openModalForm';
import { TextTypes } from '@shared/ui/modalForm/enum';
import DropDownChevronIcon from '@shared/ui/navigation/images/dropdown-chevron.svg';
import MailIcon from '@shared/ui/navigation/images/mail.svg';
import '@shared/ui/navigation/index.css';

const centerScrollOptions: ScrollIntoViewOptions = { block: 'center' };

export const Navigation = () => {
  const chevronImageRef = useRef<HTMLImageElement>(null);
  const dropdownContentRef = useRef<HTMLDivElement>(null);

  const burgerContainer = useRef<HTMLDivElement>(null);
  const burgerBtnLineFirst = useRef<HTMLDivElement>(null);
  const burgerBtnLineSecond = useRef<HTMLDivElement>(null);
  const burgerBtnLineThird = useRef<HTMLDivElement>(null);
  const burgerChevronImage = useRef<HTMLImageElement>(null);
  const burgerDropdownContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const hash = location.hash;

    burgerContainer.current!.style.display = 'none';

    if (hash) {
      const element = document.getElementById(hash.substring(1));

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  const mobileClickHandler = useCallback(() => {
    if (!matchMedia('(pointer:fine)').matches) {
      dropdownContentRef.current?.classList.toggle('dropdown-toggle');

      if (chevronImageRef.current!.style.rotate == '0deg') {
        chevronImageRef.current!.style.rotate = '180deg';
        dropdownContentRef.current!.style.pointerEvents = 'none';
      } else {
        chevronImageRef.current!.style.rotate = '0deg';
        dropdownContentRef.current!.style.pointerEvents = 'all';
      }
    }
  }, []);

  const burgerMenuClickHandler = useCallback((event: React.MouseEvent) => {
    /* eslint-disable sonarjs/no-collapsible-if */
    if (
      event.target instanceof HTMLElement &&
      event.target.parentNode instanceof HTMLElement
    ) {
      if (
        event.target.classList[0] == 'burger-btn' ||
        event.target.classList[0] == 'burger-line' ||
        event.target.tagName == 'SPAN' ||
        event.target.parentNode.classList[0] == 'burger-dropdown-content'
      ) {
        if (burgerContainer.current!.style.display == 'none') {
          burgerContainer.current!.style.display = 'flex';
          burgerBtnLineFirst.current!.style.transform =
            'rotate(45deg) translate(5px, 5px)';
          burgerBtnLineSecond.current!.style.opacity = '0';
          burgerBtnLineThird.current!.style.transform =
            'rotate(-45deg) translate(5px, -6px)';
        } else {
          burgerContainer.current!.style.display = 'none';
          burgerBtnLineFirst.current!.style.transform = '';
          burgerBtnLineThird.current!.style.transform = '';
          setTimeout(() => {
            burgerBtnLineSecond.current!.style.opacity = '1';
          }, 100);
          burgerChevronImage.current!.style.rotate = '180deg';
          burgerDropdownContentRef.current!.style.display = 'none';
        }
      }
    }
  }, []);

  const burgerDropdownMobileClickHandler = useCallback(() => {
    if (burgerChevronImage.current!.style.rotate == '0deg') {
      burgerChevronImage.current!.style.rotate = '180deg';
      burgerDropdownContentRef.current!.style.display = 'none';
    } else {
      burgerChevronImage.current!.style.rotate = '0deg';
      burgerDropdownContentRef.current!.style.display = 'flex';
    }
  }, []);

  return (
    <nav className='navigation'>
      <Link to={ROUTES.home}>
        <img src={Logo} alt='logo' />
      </Link>
      <div>
        <ScrollIntoView selector='#aboutSystem' scrollOptions={centerScrollOptions}>
          <Link className='link' to={`${ROUTES.home}#aboutSystem`}>
            <span className='text-14'>О системе</span>
          </Link>
        </ScrollIntoView>

        <div className='dropdown'>
          <button className='dropbtn' onClick={mobileClickHandler}>
            Решения
            <img
              ref={chevronImageRef}
              className='dropdown-chevron'
              src={DropDownChevronIcon}
              alt='drop down chevron'
            />
          </button>
          <div className='dropdown-content' ref={dropdownContentRef}>
            <ScrollIntoView
              className='scroll-into-view'
              selector='#documentation'
              scrollOptions={centerScrollOptions}
            >
              Рабочая документация
            </ScrollIntoView>
            <ScrollIntoView
              className='scroll-into-view'
              selector='#quality-control'
              scrollOptions={centerScrollOptions}
            >
              Контроль качества
            </ScrollIntoView>
            <ScrollIntoView
              className='scroll-into-view'
              selector='#digital-pacts'
              scrollOptions={centerScrollOptions}
            >
              КС-Менеджер
            </ScrollIntoView>
            <ScrollIntoView
              className='scroll-into-view'
              selector='#accessibility'
              scrollOptions={centerScrollOptions}
            >
              Контроль доступа на объекты
            </ScrollIntoView>
          </div>
        </div>

        <ScrollIntoView selector='#ourClients' scrollOptions={centerScrollOptions}>
          <Link className='link' to={`${ROUTES.home}#ourClients`}>
            <span className='text-14'>Наши клиенты</span>
          </Link>
        </ScrollIntoView>

        <ScrollIntoView selector='#stages' scrollOptions={centerScrollOptions}>
          <Link className='link' to={`${ROUTES.home}#stages`}>
            <span className='text-14'>Этапы</span>
          </Link>
        </ScrollIntoView>

        <ScrollIntoView selector='#about-us' scrollOptions={centerScrollOptions}>
          <Link className='link' to={`${ROUTES.home}#about-us`}>
            <span className='text-14'>О нас</span>
          </Link>
        </ScrollIntoView>

        <ScrollIntoView selector='#contacts' scrollOptions={centerScrollOptions}>
          <Link className='link' to={`${ROUTES.home}#contacts`}>
            <span className='text-14'>Контакты</span>
          </Link>
        </ScrollIntoView>

        <button
          className='text-14'
          onClick={OpenModalForm(TextTypes.navigationHeaderForm, null)}
        >
          Заказать демо
        </button>
      </div>
      <div
        className='burger-btn'
        onClick={useCallback(
          (event: React.MouseEvent) => {
            burgerMenuClickHandler(event);
          },
          [burgerMenuClickHandler],
        )}
      >
        <div className='burger-line' ref={burgerBtnLineFirst}></div>
        <div className='burger-line' ref={burgerBtnLineSecond}></div>
        <div className='burger-line' ref={burgerBtnLineThird}></div>
        <div className='burger-container' ref={burgerContainer}>
          <div className='burger-header'>
            <img src={Logo} alt='logo' />
            <ScrollIntoView selector='#aboutSystem' scrollOptions={centerScrollOptions}>
              <Link className='link' to={`${ROUTES.home}#aboutSystem`}>
                <span>О системе</span>
              </Link>
            </ScrollIntoView>

            <div className='burger-dropdown'>
              <button
                className='burger-dropdown-dropbtn'
                onClick={burgerDropdownMobileClickHandler}
              >
                Решения
                <img
                  ref={burgerChevronImage}
                  className='burger-dropdown-chevron'
                  src={DropDownChevronIcon}
                  alt='burger drop down chevron'
                />
              </button>
              <div className='burger-dropdown-content' ref={burgerDropdownContentRef}>
                <ScrollIntoView
                  selector='#documentation'
                  scrollOptions={centerScrollOptions}
                >
                  Рабочая документация
                </ScrollIntoView>
                <ScrollIntoView
                  selector='#quality-control'
                  scrollOptions={centerScrollOptions}
                >
                  Контроль качества
                </ScrollIntoView>
                <ScrollIntoView
                  selector='#digital-pacts'
                  scrollOptions={centerScrollOptions}
                >
                  КС-Менеджер
                </ScrollIntoView>
                <ScrollIntoView
                  selector='#accessibility'
                  scrollOptions={centerScrollOptions}
                >
                  Контроль доступа на объекты
                </ScrollIntoView>
              </div>
            </div>

            <ScrollIntoView selector='#ourClients' scrollOptions={centerScrollOptions}>
              <Link className='link' to={`${ROUTES.home}#ourClients`}>
                <span>Наши клиенты</span>
              </Link>
            </ScrollIntoView>

            <ScrollIntoView selector='#stages' scrollOptions={centerScrollOptions}>
              <Link className='link' to={`${ROUTES.home}#stages`}>
                <span>Этапы</span>
              </Link>
            </ScrollIntoView>

            <ScrollIntoView selector='#about-us' scrollOptions={centerScrollOptions}>
              <Link className='link' to={`${ROUTES.home}#about-us`}>
                <span>О нас</span>
              </Link>
            </ScrollIntoView>

            <ScrollIntoView selector='#contacts' scrollOptions={centerScrollOptions}>
              <Link className='link' to={`${ROUTES.home}#contacts`}>
                <span>Контакты</span>
              </Link>
            </ScrollIntoView>
          </div>
          <div className='burger-footer'>
            <Link className='mail' to={'mailto:SMLTtech@samolet.ru'}>
              <img src={MailIcon} alt='mail icon' />
              SMLTtech@samolet.ru
            </Link>
            <button onClick={OpenModalForm(TextTypes.navigationHeaderForm, null)}>
              Заказать демо
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};
